export class Cargo {
  constructor({
    single_freight_volume = null,
    total_freight_volume = null,
    single_freight_weight = null,
    total_freight_weight = null,
    cargo_type = null,
    remarks = null,
    forwarding_agent = null,
    contact_person = null,
    phone_number = null,
    email = null,
  } = {}) {
    this.single_freight_volume = single_freight_volume;
    this.total_freight_volume = total_freight_volume;
    this.single_freight_weight = single_freight_weight;
    this.total_freight_weight = total_freight_weight;
    this.cargo_type = cargo_type;
    this.remarks = remarks;
    this.forwarding_agent = forwarding_agent;
    this.contact_person = contact_person;
    this.phone_number = phone_number;
    this.email = email;
  }
}
