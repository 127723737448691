export class Offer {
  constructor({
    enquiry_id = null,
    ext_id = null,
    currency = null,
    price = null,
    client_price = null,
    has_extra_legs = false,
    operator_name = null,
    offer_type = null,
    status = "awaiting",
    is_confirmed = false,
    is_enabled = true,
    legs = [],
  } = {}) {
    this.enquiry_id = enquiry_id;
    this.ext_id = ext_id;
    this.currency = currency;
    this.price = price;
    this.client_price = client_price;
    this.has_extra_legs = has_extra_legs;
    this.operator_name = operator_name;
    this.offer_type = offer_type;
    this.status = status;
    this.is_confirmed = is_confirmed;
    this.is_enabled = is_enabled;
    this.legs = legs;
  }
}
