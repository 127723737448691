import { Cargo, Leg } from "@/store/models";

export class Enquiry {
  constructor({
    user_id = null,
    location_id = null,
    name = null,
    aircraft_types = [],
    client_preferences = null,
    has_fuel_stops = false,
    is_cargo = false,
    cargo = new Cargo(),
    legs = [new Leg()],
  } = {}) {
    this.user_id = user_id;
    this.location_id = location_id;
    this.name = name;
    this.aircraft_types = aircraft_types;
    this.client_preferences = client_preferences;
    this.has_fuel_stops = has_fuel_stops;
    this.is_cargo = is_cargo;
    this.cargo = cargo;
    this.legs = legs;
  }
}
