export class Leg {
  constructor({
    from_airport_id = null,
    to_airport_id = null,
    date = null,
    time = "12:00",
    flight_time = null,
    is_departure_time = true,
    passengers = null,
    // from_airport_name = null,
    // from_airport_city = null,
    // from_airport_country = null,
    // from_airport_iata = null,
    // from_airport_icao = null,
    // from_airport_latlong = null,
    // to_airport_name = null,
    // to_airport_city = null,
    // to_airport_country = null,
    // to_airport_iata = null,
    // to_airport_icao = null,
    // to_airport_latlong = null,
  } = {}) {
    this.from_airport_id = from_airport_id;
    this.to_airport_id = to_airport_id;
    this.date = date;
    this.time = time;
    this.flight_time = flight_time;
    this.is_departure_time = is_departure_time;
    this.passengers = passengers;
    // this.from_airport_name = from_airport_name;
    // this.from_airport_city = from_airport_city;
    // this.from_airport_country = from_airport_country;
    // this.from_airport_iata = from_airport_iata;
    // this.from_airport_icao = from_airport_icao;
    // this.from_airport_latlong = from_airport_latlong;
    // this.to_airport_name = to_airport_name;
    // this.to_airport_city = to_airport_city;
    // this.to_airport_country = to_airport_country;
    // this.to_airport_iata = to_airport_iata;
    // this.to_airport_icao = to_airport_icao;
    // this.to_airport_latlong = to_airport_latlong;
  }
}
