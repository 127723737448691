export class Location {
  constructor({
    organization_id = null,
    parent_id = null,
    name = null,
    description = null,
    company_name = null,
    address = null,
    phone = null,
    contact_name = null,
    contact_email = null,
    contact_phone = null,
    color = "#32acc8",
    banner = null,
    is_banner_active = false,
    is_whitelabel_enabled = false,
    users = null,
  } = {}) {
    this.organization_id = organization_id;
    this.parent_id = parent_id;
    this.name = name;
    this.description = description;
    this.company_name = company_name;
    this.address = address;
    this.phone = phone;
    this.contact_name = contact_name;
    this.contact_email = contact_email;
    this.contact_phone = contact_phone;
    this.color = color;
    this.banner = banner;
    this.is_banner_active = is_banner_active;
    this.is_whitelabel_enabled = is_whitelabel_enabled;
    this.users = users;
  }
}
