import { appHttp, apiHttp } from "@/services/http";
import store from "@/store";

const routes = {
  // Auth routes
  AuthCheck: "me",
  Signup: "signup",
  Logout: "logout",
  Login: "login",
  LoginChat: "login-chat",
  GLogin: "login-guest",
  Impersonate: "impersonate",
  ResendEmail: "email/resend",
  ForgotPassword: "password/email",
  VerifyPasscode: "verify-passcode",
  ConfirmPassword: "confirm-password",
  UpdatePassword: "password-update",
  UpdateEliteMembership: "elite-membership-upgrade",

  // Api routes
  AircraftModel: "aircraft-models",
  AircraftType: "aircraft-types",
  Airport: "airports",
  Bookmark: "bookmarks",
  Chat: "chat",
  Config: "config",
  Enquiry: "enquiries",
  EnquiryResend: "enquiry-resend",
  EnquiryResync: "enquiry-resync",
  EnquiryPrice: "enquiry-price",
  EnquiryEstimatedOffers: "estimated-price",
  EnquiryAirTaxiOffers: "airtaxi-price",
  EnquiryMobile: "enquiry-mobile",
  Hierarchy: "hierarchy",
  History: "history",
  KnowledgeBase: "knowledge-base",
  KnowledgeBaseTree: "knowledge-base-tree",
  Location: "locations",
  LocationUser: "location-users",
  MessageRead: "mrd",
  MessageNotification: "message-notifications",
  Offer: "offers",
  OfferConfirm: "offer-confirm",
  OfferExport: "offers-export",
  Organization: "organizations",
  ResendEnquiry: "resend-enquiry",
  User: "users",
};

const getRoute = (module) => {
  let route = routes[module];
  if (route) return `/${route}`;
  else {
    console.error("Invalid route");
    return false;
  }
};

const getRouteWithParameter = (module, payload, removeId = false) => {
  if (!("id" in payload)) {
    console.error("Missing route id");
    return false;
  }
  let route = getRoute(module);
  let id = payload.id;
  if (removeId) delete payload.id;
  return route ? `${route}/${id}` : false;
};

const notify = (res, onlyError = true) => {
  if (onlyError && !res.success) {
    store.commit("setNotifier", {
      message: res.message,
      type: "error",
    });
  } else if (!onlyError) {
    store.commit("setNotifier", {
      message: res.message,
      type: res.success ? "success" : "error",
    });
  }
};

const Api = {
  install: (Vue, { store }) => {
    if (!store) throw new Error("Please provide vuex store.");

    Vue.prototype.$app = {
      async get(module = null, payload = {}, config = {}) {
        let route = "id" in payload ? getRouteWithParameter(module, payload, true) : getRoute(module);
        let res = route ? await appHttp.get(route, payload, config) : false;
        let onlyError = "_notifyError" in payload;
        if (("_notify" in payload || onlyError) && res) notify(res.data, onlyError);
        return res;
      },

      async post(module = null, payload = {}, config = {}) {
        let route = getRoute(module);
        let res = route ? await appHttp.post(route, payload, config) : false;
        let onlyError = "_notifyError" in payload;
        if (("_notify" in payload || onlyError) && res) notify(res.data, onlyError);
        return res;
      },

      async put(module = null, payload = {}, config = {}) {
        let route = getRouteWithParameter(module, payload);
        let res = route ? await appHttp.put(route, payload, config) : false;
        let onlyError = "_notifyError" in payload;
        if (("_notify" in payload || onlyError) && res) notify(res.data, onlyError);
        return res;
      },

      async delete(module = null, payload = {}, config = {}) {
        let route = getRouteWithParameter(module, payload);
        let res = route ? await appHttp.delete(route, payload, config) : false;
        let onlyError = "_notifyError" in payload;
        if (("_notify" in payload || onlyError) && res) notify(res.data, onlyError);
        return res;
      },
    };

    Vue.prototype.$api = {
      async get(module = null, payload = {}, config = {}) {
        let route = "id" in payload ? getRouteWithParameter(module, payload, true) : getRoute(module);
        let res = route ? await apiHttp.get(route, payload, config) : false;
        let onlyError = "_notifyError" in payload;
        if (("_notify" in payload || onlyError) && res) notify(res.data, onlyError);
        return res;
      },

      async post(module = null, payload = {}, config = {}) {
        let route = getRoute(module);
        let res = route ? await apiHttp.post(route, payload, config) : false;
        let onlyError = "_notifyError" in payload;
        if (("_notify" in payload || onlyError) && res) notify(res.data, onlyError);
        return res;
      },

      async put(module = null, payload = {}, config = {}) {
        let route = getRouteWithParameter(module, payload);
        let res = route ? await apiHttp.put(route, payload, config) : false;
        let onlyError = "_notifyError" in payload;
        if (("_notify" in payload || onlyError) && res) notify(res.data, onlyError);
        return res;
      },

      async delete(module = null, payload = {}, config = {}) {
        let route = getRouteWithParameter(module, payload);
        let res = route ? await apiHttp.delete(route, payload, config) : false;
        let onlyError = "_notifyError" in payload;
        if (("_notify" in payload || onlyError) && res) notify(res.data, onlyError);
        return res;
      },
    };
  },
};

export { Api };
