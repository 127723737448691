var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    [
      _c(
        "v-overlay",
        { attrs: { absolute: "", value: _vm.$overlay(), "z-index": "10" } },
        [_c("logo", { attrs: { theme: "dark", width: 160 } })],
        1
      ),
      _c("v-progress-linear", {
        staticStyle: { "z-index": "500" },
        attrs: {
          active: _vm.$loading(),
          color: "primary",
          fixed: "",
          height: "5px",
          indeterminate: "",
          top: "",
        },
      }),
      _c("notifier"),
      _c("v-main", { staticClass: "fill-height" }, [_c("router-view")], 1),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }