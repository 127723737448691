// import Model from "../models/Model";
// import isEmpty from "lodash/isEmpty";

const initialState = () => ({
  enquiry: {},
  enquiries: [],
  enquiryErrors: {},
  enquiriesSearch: [],
  enquiriesPage: 1,
  enquiriesLastPage: 0,
  enquiryPrice: {},
  enquiryPriceHistory: {},
  enquiryPriceCurrency: "EUR",
  enquiryKey: +new Date(),
  enquiryEstimatedOffers: {},
  enquiryAirTaxiOffers: {},
  enquiryEstimatedOffersWithFuelStop: {},
});

export default {
  state: initialState(),
  actions: {
    async loadEnquiries({ commit, state }, payload = {}) {
      if (state.enquiriesPage > state.enquiriesLastPage && state.enquiriesLastPage !== 0) return 0;
      let res = await this._vm.$api.get("Enquiry", {
        ...{ page: state.enquiriesPage },
        ...payload,
      });
      if (res.status === 200) {
        commit("addMoreEnquiries", res.data.data);
        commit("setEnquiriesPage", state.enquiriesPage + 1);
        commit("setEnquiriesLastPage", res.data.meta.last_page);
        return res.data.data.length;
      }
      return -1;
    },

    async loadEnquiry({ commit, getters }, payload = {}) {
      if (payload.id === getters.getActiveEnquiry && !("_forceload" in payload)) return;
      let res = await this._vm.$api.get("Enquiry", payload);
      if (res.status === 200) {
        commit("setEnquiry", res.data.data);
        commit("setChat", res.data.data.chat);
        commit("setOffers", res.data.data.offers);
      }
    },

    async loadMobileEnquiry({ commit, getters }, payload = {}) {
      if (payload.id === getters.getActiveEnquiry && !("_forceload" in payload)) return;
      let res = await this._vm.$api.get("EnquiryMobile", payload);
      if (res.status === 200) {
        commit("setEnquiry", res.data.data);
        commit("setEnquiries", [res.data.data]);
        commit("setChat", res.data.data.chat);
      }
      return res.status !== 200;
    },

    async addEnquiry({ dispatch, commit }, payload = {}) {
      let res = await this._vm.$api.post("Enquiry", payload);
      commit("setEnquiryErrors", "errors" in res.data ? res.data.errors : {});
      if (res.status === 200 || res.status === 201) {
        dispatch("upsetEnquiriesAction", res.data.data);
      }
      return res.status === 200 || res.status === 201;
    },

    async editEnquiry({ dispatch, commit }, payload = {}) {
      let res = await this._vm.$api.put("Enquiry", payload);
      commit("setEnquiryErrors", "errors" in res.data ? res.data.errors : {});
      if (res.status === 200 || res.status === 201) {
        dispatch("upsetEnquiriesAction", res.data.data);
      }
      return res.status === 200 || res.status === 201;
    },

    async loadEnquiryPrice({ commit }, payload = {}) {
      let res = await this._vm.$api.post("EnquiryPrice", payload);
      if (res.status === 200) {
        commit("setEnquiryPrice", res.data.data);
      }
    },

    async loadEnquiryEstimatedOffers({ commit }, payload = {}) {
      let res = await this._vm.$api.post("EnquiryEstimatedOffers", payload);
      if (res.status === 200) {
        commit("setEnquiryEstimatedOffers", res.data.data);
        commit("setEnquiryEstimatedOffersWithFuelStop", res.data.data);
        // const responseData = res.data.data?.data;
        // if (responseData) {
        //   commit("setEnquiryEstimatedOffers", res.data.data.data);
        //   return true;
        // } else {
        //   return false;
        // }
        // commit("setEnquiryEstimatedOffers", res.data.data.data);
        return res?.data?.data?.data ? true : false;
      }
      return false;
    },
    async loadEnquiryAirTaxiOffers({ commit }, payload = {}) {
      let res = await this._vm.$api.post("EnquiryAirTaxiOffers", payload);
      if (res.status === 200) {
        commit("setEnquiryAirTaxiOffers", res.data.data);
        return res?.data?.data?.data.quotesAvailable;
      }
      return false;
    },

    // eslint-disable-next-line no-empty-pattern
    async resendEnquiry({}, payload = {}) {
      let res = await this._vm.$api.put("EnquiryResend", payload);
      return res.status === 200 || res.status === 201;
    },

    async resyncEnquiry({ dispatch }, payload = {}) {
      let res = await this._vm.$api.put("EnquiryResync", payload);
      if (res.status === 200 || res.status === 201) {
        dispatch("upsetEnquiriesAction", res.data.data);
      }
      return res.status === 200 || res.status === 201;
    },

    upsetEnquiriesAction({ state, commit }, data) {
      commit("upsetEnquiriesMutation", data);
      if (state.enquiry.id === data.id) {
        commit("setEnquiry", data);
        commit("setOffers", data.offers);
        commit("setEnquiryKey", +new Date());
      }
    },
  },
  mutations: {
    setEnquiry(state, data) {
      state.enquiry = data;
      state.enquiry.unread_count = 0;
      const obj = state.enquiries.find((o) => o.id === state.enquiry.id);
      if (obj) {
        obj.unread_count = 0;
      }
    },
    setEnquiries(state, data) {
      state.enquiries = data;
    },
    setEnquiryEstimatedOffers(state, data) {
      state.enquiryEstimatedOffers = data;
    },
    setEnquiryAirTaxiOffers(state, data) {
      state.enquiryAirTaxiOffers = data;
    },
    setEnquiryEstimatedOffersWithFuelStop(state, data) {
      const fuelStopData = data ? JSON.parse(JSON.stringify(data)) : {};
      if (fuelStopData?.data) {
        for (const aircraftType in fuelStopData.data.aircraftTypes) {
          const quotes = fuelStopData.data.aircraftTypes[aircraftType].quotes;
          if (quotes) {
            fuelStopData.data.aircraftTypes[aircraftType].quotes = quotes.filter((quote) => quote.fuelStop === 0);
          }
        }
      }
      state.enquiryEstimatedOffersWithFuelStop = fuelStopData;
    },
    setEnquiryKey(state, data) {
      state.enquiryKey = data;
    },
    setOffers(state, data) {
      state.offers = data;
    },
    setEnquiryErrors(state, data) {
      state.enquiryErrors = data;
    },
    setEnquiriesPage(state, data) {
      state.enquiriesPage = data;
    },
    setEnquiriesLastPage(state, data) {
      state.enquiriesLastPage = data;
    },
    setEnquiryPrice(state, data) {
      state.enquiryPrice = data.estimations;
      state.enquiryPriceHistory = data.history;
      state.enquiryPriceCurrency = data.currency;
    },
    upsetEnquiriesMutation(state, data) {
      const obj = state.enquiries.find((o) => o.id === data.id);
      obj ? Object.assign(obj, data) : state.enquiries.push(data);
    },
    addMoreEnquiries(state, data) {
      data.forEach((eq) => state.enquiries.push(eq));
    },
    updateEnquiryUpdatedAt(state, id) {
      const obj = state.enquiries.find((o) => o.id === id);
      if (obj) obj.updated_at = new Date().toISOString();
    },
    updateEnquiryUnreadCount(state, id) {
      const obj = state.enquiries.find((o) => o.id === id);
      if (obj) obj.unread_count += 1;
    },
    updateEnquiryOffersCount(state, id) {
      const obj = state.enquiries.find((o) => o.id === id);
      if (obj) obj.offers_count += 1;
    },
    updateEnquiryOffersCountMinus(state, id) {
      const obj = state.enquiries.find((o) => o.id === id);
      if (obj) obj.offers_count -= 1;
    },
    resetLoadEnquiries(state) {
      state.enquiriesPage = 1;
      state.enquiries = [];
    },
    ResetEnquiry(state) {
      const newState = initialState();
      Object.keys(newState).forEach((key) => {
        state[key] = newState[key];
      });
    },
  },

  getters: {
    getEnquiry: (state) => state.enquiry,
    getEnquiryKey: (state) => state.enquiryKey,
    getEnquiries: (state) => state.enquiries,
    getEnquiryErrors: (state) => state.enquiryErrors,
    getEnquiryPrice: (state) => state.enquiryPrice,
    getEnquiryEstimatedOffers: (state) => state.enquiryEstimatedOffers,
    getEnquiryAirTaxiOffers: (state) => state.enquiryAirTaxiOffers,
    getEnquiryEstimatedOffersWithFuelStop: (state) => state.enquiryEstimatedOffersWithFuelStop,
    getEnquiryPriceHistory: (state) => state.enquiryPriceHistory,
    getEnquiryPriceCurrency: (state) => state.enquiryPriceCurrency,
    getActiveEnquiry: (state) => (state.enquiry.id ? state.enquiry.id : null),
  },
};
