var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { "fill-height": "", fluid: "" } },
    [
      _c(
        "v-row",
        { attrs: { align: "center", justify: "center" } },
        [
          _c("v-col", [
            _c(
              "div",
              {
                staticClass:
                  "d-flex flex-column justify-center align-center pa-5",
              },
              [
                _c(
                  "v-icon",
                  { staticClass: "mb-2", attrs: { color: "secondaryText" } },
                  [_vm._v("mdi-cloud-outline")]
                ),
                _c("div", { staticClass: "text-body-2 text--secondary" }, [
                  _vm._v(_vm._s(_vm.getText)),
                ]),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }