export class User {
  constructor({
    id = null,
    organization_id = null,
    locations = [],
    first_name = null,
    last_name = null,
    email = null,
    phone = null,
    phone_code = null,
    designation = null,
    department = null,
    user_type = null,
    user_role = null,
    status = null,
    send_email = true,
  } = {}) {
    this.id = id;
    this.organization_id = organization_id;
    this.locations = locations;
    this.first_name = first_name;
    this.last_name = last_name;
    this.email = email;
    this.phone = phone;
    this.phone_code = phone_code;
    this.designation = designation;
    this.department = department;
    this.user_type = user_type;
    this.user_role = user_role;
    this.status = status;
    this.send_email = send_email;
  }
}
